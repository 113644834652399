import React, { useEffect, useState}  from 'react'
import Card from '../../Common/Cards/Card'
import Skeleton from 'react-loading-skeleton';

function HomePage() {

  const [eventData, setEventData] = useState([]);

  // states to handle the skeleton
  const [loading, setLoading]= useState(true);

  useEffect(() => {
    // Fetch JSON data from your API endpoint
    fetch('https://hstonline.tech/hstsms/api/v1/programs/21cd94e6-8aca-4d97-b8aa-38651edcee52')
      .then(response => response.json())
      .then(data => {
        console.log('Fetched data:', data);
       
        setEventData(Array(data.data));
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  console.log('eventData:', eventData);



  if (!Array.isArray(eventData)) {
    return <div>Loading...</div>;
  }



  return (
   
    <div className='text-[#2e2e9c]'>
           {loading?(
            <Skeleton count={1} height={50} duration={1} highlightColor='white' direction='rtl'/>
           ):(
           <div className="w-1/3 flex pl-5 m-auto my-5 homesearch rounded-3xl shadow-xl" style={{height: '50px'}}>
             <div className="flex justify-center items-center">
               <i className="fa fa-search"></i>
             </div>
             <input type="text" className="h-full border-none w-full pl-5 rounded-2xl" placeholder='Search for events ...'/>
           </div>
           )}

           {loading ?(
              <Skeleton count={2} height={150} duration={1} highlightColor='white'/>
           ) : (
              <div className='flex flex-wrap m-auto page'>
                {eventData.map(event => (
                  <Card key={event.id} data={event} />
                ))}
              </div>
           )}


    </div>
  )
}

export default HomePage