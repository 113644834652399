import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import Box from '@mui/material/Box';

function BasicBars({x,y,z}) {
  return (


    <Box sx={{ width: '100%' }}>
    
    <BarChart 
    

      xAxis={[
        {
          id: 'barCategories',
          data: x,
          scaleType: 'band',
          label: 'NOMINEES'
        },
      ]}
      series={[
        {
          data: y,
          label: "VOTES"
        },
      ]}

       colors={['#121213']}
      height={500}
    
    />

    </Box>


  );
}

export default BasicBars