import React, { useEffect, useState } from 'react';
import CardNominee from '../../Common/Cards/CardNominee';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { Link, useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

function Categories() {
  const { id } = useParams();


  const [catData, setCatData] = useState([]);
  const [nomData, setNomData] = useState([]);

  // skeleton loading state
  const [loading, setloading] = useState(true);

  useEffect(() => {
    // Fetch category data
    fetch(`https://hstonline.tech/hstsms/api/v1/categories/${id}`)
      .then(response => response.json())
      .then(data => {
        console.log('Fetched category data:', data);
        setCatData(data.data);
      })
      .catch(error => {
        console.error('Error fetching category data:', error);
      });

    // Fetch nominees data
    fetch(`https://hstonline.tech/hstsms/api/v1/nominees?id=${id}`)
      .then(response => response.json())
      .then(data => {
        console.log('Fetched nominees data:', data);
        setNomData(data.data);
        setloading(false);
      })
      .catch(error => {
        console.error('Error fetching nominees data:', error);
      });
  }, [id]);

  var program_id = catData.program_id;

  console.log('catData:', catData);
  console.log('nomData:', nomData);

  return (
    <section className="m-auto my-3 page">
      {loading ? (
        <Skeleton count={1} height={50} circle={true} width={50} duration={1} highlightColor='white' className='mx-auto' />
      ) : (
        <div className="w-full my-3">
          <h1 className="text-4xl font-bold flex justify-center text-blue-950 text-center">
            <Link to={`/awards/${program_id}`}>
              {' '}
              <AiOutlineArrowLeft className="text-3xl text-blue-950 font-extrabold lg:mr-20 " />
            </Link>
            {catData.name}
          </h1>
          <p className="font-light ml-3 text-center ">Nominees List</p>

          <Link to={`/results-chart/${id}`}>
            <div className='flex items-center justify-center'>
              <div className='inline-block text-white text-center font-bold cursor-pointer rounded-sm rounded-b-lg p-3 bg-[#121213] hover:bg-gray-500 active:bg-gray-700 focus:outline-none'>
                Bar Chart
              </div>
            </div>

          </Link>
        </div>

      )}
      {loading ? (
        <Skeleton count={3} height={200} direction='rtl' />
      ) : (
        <div className="flex flex-wrap justify-center">
          {nomData.map(event => (
            <CardNominee key={event.id} data={event} />
          ))}
        </div>

      )}

    </section>
  );
}

export default Categories;
