import React from 'react'

function Footer() {
  return (
    <div className='footer flex justify-center items-start bg-[#121213] py-5 text-white text-left' style={{minHeight: '400px', width: '100%'}}>
      <section className="flex flex-col text-left mx-3" style={{width: '40%'}}>
        <div className='text-left w-full mb-3 py-3' style={{borderBottom: '1px solid white'}}>
          <p>About Us</p>
        </div>
        <div>
          <p>The Award Scheme provides a platform to celebrate students with great achievements, an innovative and dynamic framework for the exchange of ideas in developing knowledge, connecting institutions, and students, equipping students with skills of picking up leadership roles, inspiring and empowering them to affect the economic development and bring social change. </p>
        </div>
      </section>
      <section className="flex flex-col text-left mx-3" style={{width: '40%'}}>
        <div className='text-left w-full mb-3 py-3' style={{borderBottom: '1px solid white'}}>
          <p>Contact Us</p>
        </div>
        <div className="flex flex-col w-full items-start text-left justify-between h-40">
          <p className='text-left'>Accra, Ghana</p>
 
          <p className='text-left'>+233204933598/+233548503799</p>
          <p className='text-left'>ghanatertiaryawards@gmail.com</p>
        </div>
      </section>
    </div>
  )
}

export default Footer