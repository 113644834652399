import React, { useEffect, useState } from 'react';
import CardNominee from '../../Common/Cards/CardNominee';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

function Nominee() {
  const { id } = useParams();

  const [nomData, setNomData] = useState([]);
  const [votePackages, setVotePackages] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [gtapin, setGtaPin] = useState('');
  const [resultsFetched, setResultsFetched] = useState(false); // New state to track results fetch status

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleInputChange = (e) => {
    setGtaPin(e.target.value);
  };

  const handleCheckResults = async () => {
    try {
      const response = await fetch(`https://hstonline.tech/hstsms/api/v1/nominee/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'accept':'application/json'
        },
        body: JSON.stringify({ gtapin }),
      });

      if (!response.ok) {
        throw new Error('Request failed!');
      }

      const data = await response.json();
      setNomData(data.data);
      setResultsFetched(true); // Mark results as fetched
      setShow(false);
      toast.success('Success');
    } catch (error) {
      toast.error('Invalid GTA PIN');
    }
  };

  useEffect(() => {
    fetch(`https://hstonline.tech/hstsms/api/v1/nominees/${id}`)
      .then((response) => response.json())
      .then((data) => {
        setNomData(data.data);

        fetch(`https://hstonline.tech/hstsms/api/v1/votePackages/${data.data.programId}`)
          .then((response) => response.json())
          .then((data) => setVotePackages(data))
          .catch((error) => console.error('Error fetching votePackages data:', error));
      })
      .catch((error) => console.error('Error fetching nominees data:', error));
  }, [id]);

  useEffect(() => {
    setState((prevState) => ({ ...prevState, nominee: nomData }));
  }, [nomData]);

  const [state, setState] = useState({
    votes: 0,
    cedi: 0,
    dollar: 0,
    num_votes: null,
    network: '',
    code: '',
    phoneNumber: '',
    nominee: nomData,
  });

  const [paymentType, setPaymentType] = useState('card');

  const handleOptionChange = (event) => {
    setPaymentType(event.target.value);
  };

  useEffect(() => {
    setState({
      ...state,
      cedi: state.nominee.voteCost * state.num_votes,
      dollar: state.cedi / state.nominee.dollarRate,
    });
  }, [state.num_votes, state.cedi, state.nominee]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    const dataToSend = {
      num_of_votes: state.num_votes,
      network: state.network,
      phoneNumber: state.phoneNumber,
      code: state.nominee.code,
      callback_url: `https://vote.ghanatertiaryawards.com/nominee/${id}`,
    };

    fetch('https://hstonline.tech/hstsms/api/v1/voteOnline2', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dataToSend),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'success') {
          toast.success(data.message);
          window.location.href = data.url;
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => console.error('Error submitting form:', error))
      .finally(() => setLoading(false));
  };

  return (
    <section className="nominee m-auto py-14 flex justify-center items-start page">
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Results</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Enter GTA PIN:</Form.Label>
            <Form.Control
              type="text"
              placeholder="GTA PIN"
              value={gtapin}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="dark" onClick={handleCheckResults}>
            View Results
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="sideone" style={{}}>
        <div className="w-full" style={{ height: '80%' }}>
          <CardNominee hideBtn data={state.nominee} />
          {!resultsFetched && ( // Hide the button when results are successfully fetched
            <button
              className="w-full bg-[#121213] h-14 hover:bg-gray-500 rounded-md"
              onClick={handleShow}
            >
              <b className="text-white">View Results</b>
            </button>
          )}
        </div>
        <ToastContainer />
      </div>

      <form action="" className="px-4 w-1/2" onSubmit={handleSubmit}>
        <div
          style={{
            background: `${paymentType === 'momo' ? 'rgb(0,23,100,.05)' : 'white'}`,
          }}
          className="form1 flex w-full flex-col items-start mb-5 border-1 border-solid border-gray-200 p-3"
        >
          <div className="w-full p-2">
            <div className="text-2xl font-bold">Dial *928*085# for USSD Voting</div>
            <select
              onChange={(e) => setState({ ...state, num_votes: e.target.value })}
              className="w-full pl-2"
              style={{ border: '2px solid gray', height: '50px' }}
              required
            >
              <option>Select Votes</option>
              {votePackages.map((option) => (
                <option key={option.id} value={option.num_of_votes}>
                  {`${option.num_of_votes} VOTES GHC${option.amount}`}
                </option>
              ))}
            </select>
          </div>
          <div className="InputField flex w-full">
            {paymentType !== 'card' && (
              <div className="w-1/2 m-2 flex flex-col items-start">
                <h1 className="text-lg">Network</h1>
                <select
                  name="network"
                  id="network"
                  className="py-2 w-full"
                  onChange={(e) => setState({ ...state, network: e.target.value })}
                  style={{ border: '2px solid gray', height: '50px' }}
                  required
                >
                  <option value="">Select Network</option>
                  <option value="MTN">MTN</option>
                  <option value="ATL">AirtelTigo</option>
                  <option value="VDF">Vodafone</option>
                </select>
              </div>
            )}
            <div className="w-full p-2">
              <h1 className="text-lg">Mobile Number</h1>
              <input
                style={{ border: '2px solid gray', height: '50px' }}
                onChange={(e) => setState({ ...state, phoneNumber: e.target.value })}
                className="mr-3 w-full pl-2"
                type="tel"
                placeholder="Enter Phone Number"
                name="phoneNumber"
                required
              />
            </div>
            <input type="hidden" value={state.nominee.code} name="code"></input>
          </div>
        </div>

        <div className="w-full">
          <button
            className="w-full bg-[#121213] h-14 hover:bg-gray-500 rounded-md"
            disabled={isLoading}
          >
            {isLoading ? (
              <span className="text-white">
                <b>Loading...</b>
              </span>
            ) : (
              <b className="text-white">Vote Now</b>
            )}
          </button>
        </div>
      </form>
    </section>
  );
}

export default Nominee;
